type Props = {
  children: React.ReactNode;
  // デフォルトの値はfalse
  isTruncate?: boolean;
};

// 本文を表すコンポーネント
function Text({ children, isTruncate = false }: Props) {
  return <p className={`break-words text-sm leading-5 ${isTruncate ? 'truncate' : ''}`}>{children}</p>;
}

export default Text;
