import { accountSdk } from '@/lib/graphql-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { providerQueryPrefix } from '@/hooks/form-view-provider';

const accountQueryPrefix = 'account';

export const useAccount = () =>
  useQuery({
    queryKey: [accountQueryPrefix],
    queryFn: () => accountSdk.account(),
  });

const saveAccountQueryPrefix = 'saveAccount';

export const useSaveAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [saveAccountQueryPrefix],
    mutationFn: accountSdk.saveAccount,
    onSuccess: (_) => {
      queryClient.invalidateQueries({
        queryKey: [accountQueryPrefix],
      });
      // 提供者に自分が含まれていることもあるので、provider系のデータもinvalidateしておく
      queryClient.invalidateQueries({
        queryKey: [providerQueryPrefix, 'account'],
      });
    },
  });
};
