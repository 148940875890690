import type { PublicView } from '@/generated/kv-graphql-client';
import type { PublicForm } from '@/generated/fb-graphql-client';
import { accountSdk, fbSdk, kvSdk } from '@/lib/graphql-client';
import { useQuery } from '@tanstack/react-query';
import { indexBy, uniq } from 'rambda';
import { useMemo } from 'react';
import type { Provider } from '@/generated/account-graphql-client';
import { hasEditUrl, makePublicUrlIdentifier, parsePublicUrl } from '@/lib/form-and-view';

const providedViewsPrefix = 'providedViews';

export const useViewProviders = () =>
  useQuery({
    queryKey: [providedViewsPrefix],
    queryFn: () => kvSdk.getProviders(),
  });

const providedFormsPrefix = 'providedForms';

export const useFormProviders = () =>
  useQuery({
    queryKey: [providedFormsPrefix],
    queryFn: () => fbSdk.getProviders(),
  });

export const providerQueryPrefix = 'provider';

// 提供者の詳細情報やフォーム/ビューの情報をIDで取得する
// accountからは提供者のプロフィール情報を取得し、同時に同ユーザーの閲覧可能なフォーム/ビューを取得し、一緒に返している
// 提供者ではない場合は、dataとしてnilを返す
export const useProvider = (id?: string) => {
  const {
    data: kvProvider,
    isPending: isKvPending,
    isError: isKvError,
  } = useQuery({
    queryKey: [providerQueryPrefix, 'kv', id],

    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      kvSdk.getProvider({ id: id! }),

    enabled: !!id,
  });
  const {
    data: fbProvider,
    isPending: isFbPending,
    isError: isFbError,
  } = useQuery({
    queryKey: [providerQueryPrefix, 'fb', id],

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => fbSdk.getProvider({ id: id! }),

    enabled: !!id,
  });

  const views = kvProvider?.provider?.views;
  const editFormUrlIdentifiers = new Set(
    (views ?? []).filter(hasEditUrl).map((x) => makePublicUrlIdentifier(parsePublicUrl(x.editUrl))),
  );
  // kviewerと連携している編集用のフォームは非表示にする
  const forms = fbProvider?.provider?.forms?.filter((x) => {
    const { subdomain, code } = parsePublicUrl(x.url);
    const publicUrlIdentifier = makePublicUrlIdentifier({ subdomain, code });
    return !editFormUrlIdentifiers.has(publicUrlIdentifier);
  });

  // フォームを一つ以上提供しているということが分かっているか
  const isLoadedAndProvidesAnyForm = !isFbPending && !!forms && forms.length > 0;
  // ビューを一つ以上提供しているということが分かっているか
  const isLoadedAndProvidesAnyView = !isKvPending && !!views && views.length > 0;
  // フォームを１つも提供していないということが分かっているか
  const isLoadedAndProvidesNoForm = !isFbPending && forms?.length === 0;
  // ビューを１つも提供していないということが分かっているか
  const isLoadedAndProvidesNoView = !isKvPending && views?.length === 0;

  // 実際にビューかフォームを一つ以上提供しているかどうか。
  const isLoadedAndProvidesAnyData = isLoadedAndProvidesAnyForm || isLoadedAndProvidesAnyView;

  // 実際にビューかフォームを１つも提供していないかどうか。提供していない場合はアカウント情報も返さない
  const isLoadedAndProvidesNoData = isLoadedAndProvidesNoForm && isLoadedAndProvidesNoView;

  const {
    data: accountProvider,
    isPending: isAccountPendingOrDisabled,
    isError: isAccountError,
  } = useQuery({
    queryKey: [providerQueryPrefix, 'account', id],

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => accountSdk.getProvider({ id: id! }),

    enabled: isLoadedAndProvidesAnyData && !!id,
  });

  // フォーム/ビューを一つも提供していない場合は、そのことが分かった時点でロードが終わった扱いにする
  // 提供者の取得に失敗した場合や、フォーム/ビュー両方とも取得に失敗した場合も有用なデータが返せないので終わった扱いにする
  // している場合はアカウント情報のロードが終わるまで待つ
  const hasLoaded =
    (isLoadedAndProvidesAnyData && !isAccountPendingOrDisabled) ||
    isLoadedAndProvidesNoData ||
    isAccountError ||
    (isKvError && isFbError);

  const isError = isKvError || isFbError || isAccountError;

  if (isLoadedAndProvidesNoData) {
    return {
      data: null,
      isPending: false,
      isError,
    };
  }

  if (!hasLoaded) {
    return {
      data: null,
      isPending: true,
      isError,
    };
  }

  const provider = accountProvider?.providers[0];

  // アカウントデータが得られなかった場合もnullを返す
  if (!provider) {
    return {
      data: null,
      isPending: false,
      isError,
    };
  }

  return {
    data: {
      ...provider,
      forms: forms ?? [],
      views: views ?? [],
    },
    isPending: !hasLoaded,
    isError,
  };
};

// フォーム/ビューを１つ以上提供しており、かつ名前が設定されている、一覧で表示可能な提供者の情報を取得する
export const useVisibleProviders = () => {
  const { data: kvProviders, isPending: isKvPending } = useFormProviders();
  const { data: fbProviders, isPending: isFbPending } = useViewProviders();

  // 一つ以上のフォーム/ビューを提供しているproviderIdのみ取り出す
  const providerIds = useMemo(() => {
    if (isKvPending || isFbPending) return null;

    return uniq([
      ...(fbProviders?.providers ?? []).map((x) => x.providerId),
      ...(kvProviders?.providers ?? []).map((x) => x.providerId),
    ]);
  }, [fbProviders?.providers, isFbPending, isKvPending, kvProviders?.providers]);

  const { data: accountProviders, isPending: isAccountPendingOrDisabled } = useQuery({
    queryKey: [providerQueryPrefix, 'account'],
    queryFn: () => accountSdk.getProviders({ ids: providerIds ?? [] }),
    enabled: providerIds !== null && providerIds.length > 0,
  });

  // 提供しているフォーム/ビューが一つも無い場合は、account情報を取得せず、ロードが終わった扱いにする
  const hasLoaded =
    !isAccountPendingOrDisabled || (!isKvPending && !isFbPending && providerIds !== null && providerIds.length === 0);

  if (!hasLoaded) {
    return {
      data: null,
      isPending: true,
    };
  }

  return {
    data: accountProviders?.providers ?? [],
    isPending: false,
  };
};

export type ProviderWithViewAndForms = Partial<Provider> & { forms: PublicForm[]; views: PublicView[] };

// 提供者の詳細情報やフォーム/ビューの情報を取得する
// accountからは提供者のプロフィール情報を取得し、同時に同ユーザーの閲覧可能なフォーム/ビューを取得し、一緒に返している
// 提供者が１人もいない場合は当然だがdataとして空の配列を返す
export const useAllProvidedFormsAndViewsWithProvider = () => {
  const { data: kvProviders, isPending: isKvPending } = useViewProviders();
  const { data: fbProviders, isPending: isFbPending } = useFormProviders();

  // 一つ以上のフォーム/ビューを提供しているproviderIdのみ取り出す
  const providerIds = useMemo(() => {
    if (isKvPending || isFbPending) return null;

    return uniq([
      ...(fbProviders?.providers ?? []).map((x) => x.providerId),
      ...(kvProviders?.providers ?? []).map((x) => x.providerId),
    ]);
  }, [fbProviders?.providers, isFbPending, isKvPending, kvProviders?.providers]);

  const { data: accountProviders, isPending: isAccountPendingOrDisabled } = useQuery({
    queryKey: [providerQueryPrefix, 'account'],
    queryFn: () => accountSdk.getProviders({ ids: providerIds ?? [] }),
    enabled: providerIds != null && providerIds.length > 0,
  });

  // 提供しているフォーム/ビューが一つも無い場合は、account情報を取得せず、ロードが終わった扱いにする
  const hasLoaded =
    !isAccountPendingOrDisabled || (!isKvPending && !isFbPending && providerIds !== null && providerIds.length === 0);

  const mergedProviders: ProviderWithViewAndForms[] = useMemo(() => {
    if (!accountProviders?.providers || !providerIds) return [];

    const providerByProviderId = indexBy((account) => account.providerId, accountProviders?.providers ?? []);

    const viewsByProviderId = kvProviders
      ? Object.fromEntries((kvProviders.providers ?? []).map((kvProvider) => [kvProvider.providerId, kvProvider.views]))
      : {};

    const editUrlIdentifiersByProviderId = kvProviders
      ? Object.fromEntries(
          kvProviders.providers.map((kvProvider) => [
            kvProvider.providerId,
            new Set(
              kvProvider.views.filter(hasEditUrl).map((view) => makePublicUrlIdentifier(parsePublicUrl(view.editUrl))),
            ),
          ]),
        )
      : {};

    const formsByProviderId = fbProviders
      ? Object.fromEntries(
          fbProviders.providers.map((fbProvider) => [
            fbProvider.providerId,
            // kviewerと連携している編集用のフォームは非表示にする
            fbProvider.forms.filter((x) => {
              const { subdomain, code } = parsePublicUrl(x.url);
              const publicUrlIdentifier = makePublicUrlIdentifier({ subdomain, code });
              return !editUrlIdentifiersByProviderId[fbProvider.providerId]?.has(publicUrlIdentifier);
            }),
          ]),
        )
      : {};

    return providerIds.map((providerId) => ({
      ...providerByProviderId[providerId],
      forms: formsByProviderId[providerId] ?? [],
      views: viewsByProviderId[providerId] ?? [],
    }));
  }, [accountProviders?.providers, providerIds, fbProviders, kvProviders]);

  if (!hasLoaded) {
    return {
      data: null,
      isPending: true,
    };
  }

  return {
    data: mergedProviders,
    isPending: false,
  };
};
