import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

// サービス名「Toyokumo kintoneApp」を表示する
function ToyokumoKintoneAppText({ width, height }: Props) {
  return <Image width={width} height={height} src="/toyokumo-kintoneapp-text.svg" alt="Toyokumo kintoneApp" priority />;
}

export default ToyokumoKintoneAppText;
