import Link from 'next/link';

type BasicLinkProps = {
  children: React.ReactNode;
  href: string;
  target?: string;
  icon?: React.ReactNode;
};

function BasicLink({ children, href, target, icon }: BasicLinkProps) {
  const text = (
    <span className="inline-flex items-center">
      {children}
      {icon}
    </span>
  );

  if (href.startsWith('/')) {
    return (
      <Link href={href} className="cursor-pointer text-role-action hover:underline">
        {text}
      </Link>
    );
  }

  return (
    <a
      className="cursor-pointer text-role-action hover:underline"
      href={href}
      {...(target ? { target } : {})}
      rel="noreferrer"
    >
      {text}
    </a>
  );
}

export default BasicLink;
