import { useTranslation } from '@/hooks/i18n';
import { MdCheck, MdMenu } from 'react-icons/md';
import SubText from '@/components/typography/SubText';
import SideMenu from '@/components/SideMenu';
import { ProviderList } from '@/components/ProviderList';
import type { RefObject } from 'react';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Text from '@/components/typography/Text';
import { selectAvatarColor } from '@/lib/select-avatar-color';
import { NEXT_PUBLIC_SERVICE_ORIGIN } from '@/config/env-client';
import { LanguageSwitchingButton } from '@/components/LanguageSwitchingButton';
import BasicLink from '@/components/BasicLink';
import { APP_VERSION } from '@/config/version';
import ProviderIcon from '@/components/ProviderIcon';

type FilterChipProps = {
  type: 'user' | 'service';
  children: React.ReactNode;
};

function FilterChip({ type, children }: FilterChipProps) {
  const router = useRouter();
  const checkCurrent = () => {
    // '/'または'/account/'で始まるパスの場合はユーザーページ
    if (type === 'user') {
      return router.pathname === '/' || router.pathname.startsWith('/account/');
    }
    // '/app/'で始まるパスの場合はサービス管理
    return router.pathname === '/app' || router.pathname.startsWith('/app/');
  };
  const isCurrent = checkCurrent();

  return isCurrent ? (
    <div
      // https://www.figma.com/file/hapKHRQmoJhTjWQby50Gfq/k%E9%80%A3%E6%90%BA-%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0?node-id=1069%3A3908&t=tICO9q7tEYTf4t7G-0
      // hoverはベースの色にrbga(0, 0, 0, 0.05)を重ねて表現しているが、
      // 使用される数も少ないためarbitrary valuesとして記述している
      className="flex items-center space-x-2 rounded bg-role-action px-2 py-1 hover:bg-[#0072c7]"
    >
      <MdCheck className="text-white" size={18} />
      <span className="truncate text-xs font-bold text-white">{children}</span>
    </div>
  ) : (
    <Link href={type === 'user' ? '/' : '/app'}>
      <div className="flex items-center space-x-2 rounded bg-white px-2 py-1 hover:bg-role-hover">
        <span className="truncate text-xs font-bold text-role-action">{children}</span>
      </div>
    </Link>
  );
}

export const NavigationDrawerContext = createContext<
  | {
      showNavigationDrawer: boolean;
      setShowNavigationDrawer: (v: boolean) => void;
    }
  | undefined
>(undefined);

const useNavigationDrawerContext = () => {
  const context = useContext(NavigationDrawerContext);
  if (!context) {
    throw new Error('NavigationDrawerContext.Provider内で使用してください');
  }
  return context;
};

type NavigationDrawerProps = {
  avatarUrl: string | null;
  name: string | null;
  email: string;
  uid: string;
  hasProviderList?: boolean;
  isInApp?: boolean;
};

const useOnClickOutside = (refs: RefObject<HTMLElement>[], handler: (event: Event) => void) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (
        refs.some((ref) => {
          const el = ref?.current;
          return !el || el.contains(event?.target as Node);
        })
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
};

// eslint-disable-next-line import/prefer-default-export
export function NavigationDrawer({
  avatarUrl,
  name,
  email,
  uid,
  hasProviderList = true,
  isInApp = false,
}: NavigationDrawerProps) {
  const { t } = useTranslation();
  const avatarColor = name == null || name.length === 0 ? selectAvatarColor('') : selectAvatarColor(uid);
  const navigationDrawerContext = useNavigationDrawerContext();
  const width = navigationDrawerContext.showNavigationDrawer ? 'w-[240px] lg:w-[320px]' : 'w-0';
  const buttonRef = useRef<HTMLButtonElement>(null);
  const asideRef = useRef<HTMLElement>(null);
  useOnClickOutside([buttonRef, asideRef], (_) => {
    navigationDrawerContext.setShowNavigationDrawer(false);
  });
  return (
    <>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        ref={buttonRef}
        className={`px-2 py-3 ${
          navigationDrawerContext.showNavigationDrawer ? 'bg-role-selected' : 'hover:bg-role-hover'
        }`}
        onClick={() => {
          navigationDrawerContext.setShowNavigationDrawer(!navigationDrawerContext.showNavigationDrawer);
        }}
      >
        <div className="hidden lg:block">
          <ProviderIcon size={24} src={avatarUrl} fallbackColor={avatarColor} />
        </div>
        <div className="lg:hidden">
          <MdMenu size={24} />
        </div>
      </button>
      <aside
        ref={asideRef}
        className={`fixed right-0 top-12 z-40 flex h-[calc(100vh_-_3rem)] flex-col overflow-y-auto bg-white text-black shadow-shallow transition-all duration-150 ease-in-out ${width}`}
      >
        <nav>
          <div className="px-4 pt-6">
            <div className="space-y-4 py-4">
              <div className="px-4">
                <ProviderIcon size={48} src={avatarUrl} fallbackColor={avatarColor} />
              </div>
              <div className="space-y-1 whitespace-normal break-words px-4">
                {name && (
                  <div className="line-clamp-2">
                    <Text>{name}</Text>
                  </div>
                )}
                <div className="line-clamp-2">
                  <SubText>{email}</SubText>
                </div>
              </div>
              <div>
                {/* サービス管理下からログアウトするときはサービス管理トップ(/app)に戻れるようにbackUrlを設定 */}
                <SideMenu href={isInApp ? `/logout?backUrl=${NEXT_PUBLIC_SERVICE_ORIGIN}/app` : '/logout'} isTruncate>
                  {t('logout')}
                </SideMenu>
              </div>
            </div>
            {hasProviderList && (
              // 言語切り替えボタン+バージョンアップ情報の高さ分padding-bottomを設定
              <div className="pb-[4.5rem] lg:hidden">
                <ProviderList />
              </div>
            )}
            <div className={`${hasProviderList ? 'hidden lg:flex' : 'flex'} space-x-2 bg-toyokumo-gray-50 p-4`}>
              <FilterChip type="user">{t('userPage')}</FilterChip>
              <FilterChip type="service">{t('manageServices')}</FilterChip>
            </div>
          </div>
          <div className="fixed bottom-0 z-50 w-full bg-white px-4 pb-4 pt-2">
            <LanguageSwitchingButton />
            <div className="mt-4 flex space-x-4 text-xs">
              <BasicLink href="https://www.toyokumo.co.jp/policy" target="_blank">
                プライバシーポリシー
              </BasicLink>
              <BasicLink
                href="https://toyokumo.viewer.kintoneapp.com/public/toyokumo-kintoneapp-account-versionup-info"
                target="_blank"
              >
                Ver. {APP_VERSION}
              </BasicLink>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
}
