type Props = {
  children: React.ReactNode;
  error?: boolean;
  // デフォルトの値はfalse
  isTruncate?: boolean;
};

// 補足情報を表すコンポーネント
function SubText({ children, error, isTruncate = false }: Props) {
  return (
    <p
      className={`break-words text-xs leading-4 ${error ? 'text-role-error' : 'text-[#999999]'} ${
        isTruncate ? 'truncate' : ''
      }`}
    >
      {children}
    </p>
  );
}

export default SubText;
