import Image from 'next/image';

type Props = {
  width: number;
  height: number;
};

// トヨクモのロゴ
function ToyokumoLogo({ width, height }: Props) {
  return <Image width={width} height={height} src="/toyokumo-logo.svg" alt="Toyokumo logo" />;
}

export default ToyokumoLogo;
