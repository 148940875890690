import { MdAccountCircle } from 'react-icons/md';

type ProviderIconProps = {
  size: number;
  src: string | null;
  fallbackColor?: string;
};

function ProviderIcon({ size, src, fallbackColor }: ProviderIconProps) {
  if (!src || src.length === 0) {
    return <MdAccountCircle size={size} color={fallbackColor} />;
  }
  return (
    // 任意の画像が入り、コストがかかり過ぎる恐れがあるため、next/imageでの最適化を行わない
    // cf. https://scrapbox.io/toyokumo/account-kintoneapp_%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%83%9A%E3%83%BC%E3%82%B8%E8%A8%AD%E8%A8%88#63d8b9eb03424100004e42aa
    // eslint-disable-next-line @next/next/no-img-element
    <img src={src} className="rounded-full object-cover" style={{ width: size, height: size }} alt="提供者のアイコン" />
  );
}

export default ProviderIcon;
