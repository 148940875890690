import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  href: string;
  children: React.ReactNode;
  // デフォルトの値はfalse
  isTruncate?: boolean;
};

// 1つのサイドメニューを表す
function SideMenu({ href, children, isTruncate = false }: Props) {
  const router = useRouter();

  return (
    <Link href={href}>
      <div
        className={`rounded py-2.5 pl-4 text-sm ${router.pathname === href ? 'bg-role-selected' : 'hover:bg-role-hover'}
        ${isTruncate ? 'truncate' : ''}`}
      >
        {children}
      </div>
    </Link>
  );
}

export default SideMenu;
