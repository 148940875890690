import React, { createContext, useContext } from 'react';
import { MdAccountCircle } from 'react-icons/md';
import Text from '@/components/typography/Text';
import Link from 'next/link';
import { useTranslation } from '@/hooks/i18n';
import GrayToyokumoIcon from '@/svgs/icons/grayToyokumo.svg';
import SubText from '@/components/typography/SubText';
import SideMenu from '@/components/SideMenu';
import { IoMdInformationCircle } from 'react-icons/io';
import { selectAvatarColor } from '@/lib/select-avatar-color';
import { useRouter } from 'next/router';
import { Spinner } from '@toyokumo/kintoneapp-ui';
import ProviderIcon from '@/components/ProviderIcon';

type ProviderMenuLinkProps = {
  id: string;
  avatarUrl: string | null;
  children: React.ReactNode;
  href: string;
};

function ProviderMenu({ id, avatarUrl, children, href }: ProviderMenuLinkProps) {
  const router = useRouter();
  const isSelected = router.asPath === href;
  return (
    <div
      className={`flex items-center space-x-2 rounded py-2 pl-2 pr-4 ${
        isSelected ? 'bg-role-selected' : 'hover:bg-role-hover'
      }`}
    >
      <span className="shrink-0">
        <ProviderIcon size={24} src={avatarUrl} fallbackColor={selectAvatarColor(id)} />
      </span>
      <div className="line-clamp-2 whitespace-normal break-all">
        <Text>{children}</Text>
      </div>
    </div>
  );
}

function ProviderMenuLink({ id, avatarUrl, children, href }: ProviderMenuLinkProps) {
  return (
    <Link href={href}>
      <ProviderMenu id={id} avatarUrl={avatarUrl} href={href}>
        {children}
      </ProviderMenu>
    </Link>
  );
}

export type ProviderListType = {
  providerList?: {
    id: string;
    name: string;
    href: string;
    avatarUrl: string | null;
  }[];
};

const ProviderListContext = createContext<ProviderListType | null | undefined>(undefined);

const useProviderListContext = () => {
  const context = useContext(ProviderListContext);
  if (!context) {
    throw new Error('ProviderListContext.Provider内で使用してください');
  }
  return context;
};

function Loading() {
  return (
    <div className="flex items-center space-x-2 rounded py-2 pl-2 pr-4">
      <span className="shrink-0 text-toyokumo-gray-200">
        <ProviderIcon size={24} src={null} />
      </span>
      <div className="grid grow justify-items-center">
        <Spinner />
      </div>
    </div>
  );
}

function EmptyStatement() {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-2 bg-toyokumo-gray-50 py-2 pl-2 pr-4">
      <span className="shrink-0">
        <IoMdInformationCircle className="text-role-caution" size={24} />
      </span>
      <div className="line-clamp-2 whitespace-normal break-all">
        <Text>{t('user.noProvider')}</Text>
      </div>
    </div>
  );
}

function ProviderList({ isPreview = false }: { isPreview?: boolean }) {
  const { t } = useTranslation();
  const providerListContext = useProviderListContext();

  return (
    <div
      className={`border-t border-b-toyokumo-gray-200 ${isPreview ? 'border-none' : 'lg:border-none'} space-y-8 pt-8`}
    >
      <div className="space-y-2">
        <div className="flex space-x-1 px-4">
          <span className="shrink-0">
            <GrayToyokumoIcon />
          </span>
          <SubText isTruncate>Toyokumo kintoneApp</SubText>
        </div>
        <div>
          {isPreview ? (
            <div className="rounded py-2.5 pl-4 text-sm">{t('all')}</div>
          ) : (
            <SideMenu href="/" isTruncate>
              {t('all')}
            </SideMenu>
          )}
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex space-x-1 px-4">
          <span className="shrink-0">
            <MdAccountCircle size={16} color="#999999" />
          </span>
          <SubText isTruncate>{t('providers')}</SubText>
        </div>
        {(() => {
          if (!providerListContext.providerList) {
            return <Loading />;
          }
          if (providerListContext.providerList.length === 0) {
            return <EmptyStatement />;
          }
          return (
            <ul>
              {providerListContext.providerList.map(({ id, name, href, avatarUrl }) => (
                <li key={id}>
                  {isPreview ? (
                    <ProviderMenu id={id} href={href} avatarUrl={avatarUrl}>
                      {name}
                    </ProviderMenu>
                  ) : (
                    <ProviderMenuLink id={id} avatarUrl={avatarUrl} href={href}>
                      {name}
                    </ProviderMenuLink>
                  )}
                </li>
              ))}
            </ul>
          );
        })()}
      </div>
    </div>
  );
}

export { ProviderListContext, ProviderList };
