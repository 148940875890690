import type { PublicView } from '@/generated/kv-graphql-client';

export const parsePublicUrl = (url: string): { subdomain: string; code: string } => {
  const parsedUrl = new URL(url);
  const paths = parsedUrl.pathname.split('/');
  const subdomain = parsedUrl.hostname.split('.')[0];
  const code = paths[paths.length - 1];
  return {
    subdomain,
    code,
  };
};

export const makePublicUrlIdentifier = ({ subdomain, code }: { subdomain: string; code: string }) =>
  `${subdomain}-${code}`;

export const hasEditUrl = (x: PublicView): x is PublicView & { editUrl: string } => !!x.editUrl;
