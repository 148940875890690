// 会社/組織名が入力済みかつ画像が未アップロードの場合のユーザーアイコンの色を割り振る
// 変更されず、ランダムに割り振るためuidの文字コードを足しあげ、6でMODをとっている
export const selectAvatarColor = (uid: string) => {
  if (uid.length === 0) {
    return '#000000';
  }
  const avatarColors = ['#006CBB', '#005FA3', '#0B7D9E', '#085B75', '#178069', '#0F645D'] as const;
  const colorNumber = [...uid].map((s) => s.charCodeAt(0)).reduce((acc, n) => (acc + n) % 6, 0);
  return avatarColors[colorNumber];
};
