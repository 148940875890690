type Spaces = 1 | 2 | 4 | '[3px]';

type Props = {
  logo: React.ReactNode;
  text: React.ReactNode;
  space: Spaces;
};

const SPACE_CLASS: Record<Spaces, string> = {
  1: 'space-x-1',
  2: 'space-x-2',
  4: 'space-x-4',
  '[3px]': 'space-x-[3px]',
} as const;

// 水平にロゴとテキストを並べるコンポーネント
function HorizontalLogo({ logo, text, space }: Props) {
  return (
    <div className={`flex items-center ${SPACE_CLASS[space]}`}>
      <div className="shrink-0">{logo}</div>
      <div className="shrink-0">{text}</div>
    </div>
  );
}

export default HorizontalLogo;
